import "./form-input.styles.scss";

const FormInput = ({
  value,
  label,
  style,
  styleInput,
  styleLabel,
  ...otherProps
}) => {
  return (
    <div className="form-input-container" style={style}>
      {label && (
        <label style={styleLabel} className="form-input-label">
          {label}
        </label>
      )}
      <input
        className="form-input"
        value={value}
        style={styleInput}
        {...otherProps}
      ></input>
    </div>
  );
};

export default FormInput;
