import axios from "axios";
import { BASE_URL } from "../utils/consts";

const createReducePaymentIntentAPI = async (
  bookedTicketId,
  productName,
  defaultReduceTicketId
) => {
  const responseObject = {
    bookedTicketId,
    productName,
    defaultTicketId: defaultReduceTicketId,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/stripe/createReducePaymentIntent`,

      responseObject,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.data;
    return data;
  } catch (err) {
    throw err;
  }
};

export default createReducePaymentIntentAPI;
