import React, { useState, Fragment } from "react";
import { checkConditionForSocialPopup } from "../../utils/helpers";
import SocialPopupChildComponent from "../popup/child-components/social-media/social-media";
import PopupGlobal from "../popup/popup";
import UserInfo from "../user-info/user-info";
import "./payment-success.scss";

const PaymentSuccessCard = ({
  payLoad,
  paymentPayLoad,
  // selectedTicket,
  availableTicket,
}) => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showGlobalPopUp, setShowGlobalPopUp] = useState(true);

  const onClickHandler = () => {
    window.location.reload();
  };

  if (showUserInfo && payLoad) {
    return (
      // <div className="profile-card">
      // <CheckoutForm payLoad={payLoad} />
      // </div>
      // <div className="profile-card">
      <Fragment>
        <UserInfo payLoad={payLoad} />

        {showGlobalPopUp &&
          payLoad &&
          checkConditionForSocialPopup(payLoad) && (
            <PopupGlobal
              setShowGlobalPopup={setShowGlobalPopUp}
              showGlobalPopup={showGlobalPopUp}
              ChildComponent={() => (
                <SocialPopupChildComponent
                  instagramLink={payLoad.event.socialMedia.instagramUrl}
                  facebookLink={payLoad.event.socialMedia.facebookUrl}
                  youtubeLink={payLoad.event.socialMedia.youtubeUrl}
                  tiktokLink={payLoad.event.socialMedia.tiktokUrl}
                  whatsAppLink={payLoad.event.socialMedia.whatsAppUrl}
                />
              )}
              popupHeading="Follow us on"
            />
          )}
      </Fragment>
      // </div>
    );
  }

  //! reference number is booked ticket id shown as refernce number

  return (
    <div className="payment-success-container">
      <div className="success-circle">
        <div className="checkmark">✔</div>
      </div>

      <h2>Payment Successful</h2>
      <div className="line-separator" />

      <p>
        <span className="pay-method">Code: </span>
        <span className="amount">{payLoad.code}</span>
      </p>

      <p>
        <span className="pay-method">Amount Paid:</span>{" "}
        <span className="amount">${availableTicket.price}</span>
      </p>

      <p>
        <span className="pay-method">Processing Fee:</span>{" "}
        <span className="amount">${availableTicket.processingFee}</span>
      </p>

      <span className="pay-method">Ticket:</span>
      <span className="amount"> {availableTicket.ticketType.name}</span>

      <p>
        <span className="pay-method">Reference Number: </span>
        <span className="amount">{payLoad.id}</span>
      </p>

      <div className="line-separator" />
      <button className="view-ticket-button" onClick={onClickHandler}>
        View Ticket
      </button>
    </div>
  );
};

export default PaymentSuccessCard;
