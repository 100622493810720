import "./checkout-form.scss";
import { Fragment, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { convertTimestamp } from "../../../utils/helpers";
import BeatSpinner from "../../loading-indicator/beat-loader";
// import LoadingSpinner from "../loading-indicator/loading-indicator.component";
// import UserInfo from "../user-info/user-info";
// import PopupGlobal from "../popup/popup";
// import { checkConditionForSocialPopup } from "../../utils/helpers";
// import SocialPopupChildComponent from "../popup/child-components/social-media/social-media";
import PaymentSuccessCard from "../../payment-success/payment-success";
import FormInput from "../../form-input/form-input.component";
import { validateEmail } from "../../../utils/helpers";
import updateGuestNameAPI from "../../../apis/update-guest";

const defaultFormFields = {
  email: "",
};

const CheckoutForm = ({
  payLoad,
  clientSecret,
  // selectedTicket,
  availableTicket,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessView, setShowSuccessView] = useState(false);
  const [showGlobalPopUp, setShowGlobalPopUp] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentPayLoad, setPaymentPayLoad] = useState({});
  const [newFormFields, setNewFormFields] = useState(defaultFormFields);

  const { email } = newFormFields;

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    const updatedFormFields = { ...newFormFields, [name]: value };
    setNewFormFields(updatedFormFields);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setIsLoading(true);

      if (!email.trim()) {
        alert("Email cannot be empty");
        return;
      }

      if (!validateEmail(email)) {
        alert("Email is not valid");
        return;
      }

      const quickInviteResponse = await updateGuestNameAPI(
        payLoad.event.id,
        payLoad.id,
        payLoad.uniqueHash,
        payLoad.fullName,
        email
      );

      if (!stripe || !elements) {
        throw new Error(
          "An error occured while initializing the payment intent"
        );
      }

      elements.submit();

      if (quickInviteResponse.status === "Success") {
        const result = await stripe.confirmPayment({
          elements,
          clientSecret: clientSecret,
          redirect: "if_required",
        });

        if (result.error) {
          throw new Error(result.error.message);
        }

        setPaymentPayLoad(result.paymentIntent);

        if (result.paymentIntent.status === "succeeded") {
          setShowSuccessView(true);
        }
      }
    } catch (error) {
      error.response?.data.message && alert(error.response?.data.message);
      setErrorMessage(
        error.response?.data.message
          ? error.response?.data.message
          : error.message
      );
      console.error(
        error.response?.data.message
          ? error.response?.data.message
          : error.message
      );
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  // if (showUserInfo && payLoad) {
  //   return (
  //     // <div className="profile-card">
  //     // <CheckoutForm payLoad={payLoad} />
  //     // </div>
  //     // <div className="profile-card">
  //     <Fragment>
  //       <UserInfo payLoad={payLoad} />

  //       {showGlobalPopUp &&
  //         payLoad &&
  //         checkConditionForSocialPopup(payLoad) && (
  //           <PopupGlobal
  //             setShowGlobalPopup={setShowGlobalPopUp}
  //             showGlobalPopup={showGlobalPopUp}
  //             ChildComponent={() => (
  //               <SocialPopupChildComponent
  //                 instagramLink={payLoad.event.socialMedia.instagramUrl}
  //                 facebookLink={payLoad.event.socialMedia.facebookUrl}
  //                 youtubeLink={payLoad.event.socialMedia.youtubeUrl}
  //                 tiktokLink={payLoad.event.socialMedia.tiktokUrl}
  //                 whatsAppLink={payLoad.event.socialMedia.whatsAppUrl}
  //               />
  //             )}
  //             popupHeading="Follow us on"
  //           />
  //         )}
  //     </Fragment>
  //     // </div>
  //   );
  // }

  if (showSuccessView) {
    return (
      <PaymentSuccessCard
        payLoad={payLoad}
        paymentPayLoad={paymentPayLoad}
        // selectedTicket={selectedTicket}
        availableTicket={availableTicket}
      />
    );
  }

  return (
    <Fragment>
      <h1 className="event-name">{payLoad.event.eventName}</h1>
      <span className="event-date">
        {convertTimestamp(payLoad.event.eventDate)}
      </span>
      <span className="event-place">{payLoad.event.location}</span>
      <div className="ticket-info-container">
        <h3 className="ticket-info-header">Ticket Information</h3>
        <span className="ticket-info-details">
          <strong>Price:</strong> ${payLoad.availableTicket.price}{" "}
        </span>
        <span className="ticket-info-details">
          <strong>Processing fee:</strong> $
          {payLoad.availableTicket.processingFee}{" "}
        </span>
        <span className="ticket-info-details">
          <strong>Total Amount: </strong> $
          {Number(payLoad.availableTicket.processingFee) +
            Number(payLoad.availableTicket.price)}{" "}
        </span>
      </div>
      <div className="line-separator" />

      <div className="payment-form-container">
        <form>
          <FormInput
            label="Email"
            type="text"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={onChangeHandler}
            style={{
              width: "100%",
              display: "flex",
            }}
            styleInput={{
              height: "40px",
            }}
            required
          />
        </form>
      </div>

      <h3 className="payment-info-header">Add Payment Information</h3>

      <form className="payment-form" onSubmit={handleSubmit}>
        <PaymentElement />
        <button className="payment-button" type="submit" disabled={!stripe}>
          {isLoading ? <BeatSpinner /> : "Pay"}
        </button>
        {errorMessage && (
          <span className="error-message-payment">{errorMessage}</span>
        )}
      </form>
    </Fragment>
  );
};

export default CheckoutForm;
